/**
 *  项目的配置文件
 */
//因为如果从common.js引入，而request又引入common, common也引入request。会出问题，所以直接在此定义getQueryVariable
const getQueryVariable = (variable) => {
     let query = window.location.search.substring(1);
     let vars = query.split("&");
     for (let i = 0; i < vars.length; i++) {
          let pair = vars[i].split("=");
          if (pair[0] == variable) {
               return pair[1];
          }
     }
     return false;
}

//确保第一时间获取到 identity值，要不然打开则undefined
export const getIdentity = () => {
     if (localStorage.identity && localStorage.identity != 'false') {
          return localStorage.identity
     } else {
          if (getQueryVariable('storeId')) return 'seller'
          if (getQueryVariable('adminId')) return 'admin'
     }
}

export const identity = getIdentity()

export const apiUrl = `https://scp${getIdentity()}.dcz.cn/`//接口请求地址
export const chatUrl = 'wss:// scpim.dcz.cn'//客服地址
export const sourceUrl = `https://scp${getIdentity()}.dcz.cn/`;//来源地址，当用户失去登录态时需跳转到来源登录页
export const pcUrl = ''  //没有PC终端，这里无需配置，直接留空即可
export const curLang = 'zh';//当前语言,zh:中文，若为其他语言，需要对应/src/assets/language下面的文件名

/** copyright *** slodon *** version-v4.2 *** date-2023-02-20 ***主版本v4.6**/



